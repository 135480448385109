import React from "react";
// import HeadLogo from "../../assets/img/boost-logo.png";
import axios from "axios";
import HeadLogo from "../../assets/img/logo.png";
import { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  Button,
  Offcanvas,
  Dropdown,
} from "react-bootstrap";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import headerLogoNew from "../../assets/img/header-logo-new.png";
import ImageTag from "../ImageTag";

import { useNavigate, Link } from 'react-router-dom';
import Loader from "../Loading/Loader";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { authActions } from "../../store/auth";
import { useSelector, useDispatch } from "react-redux";
import { useHttpClient } from "../../hooks/HttpHook";

const UserLogin = () => {
// console.log('dispatch');
  const dispatch = useDispatch();

  const { sendRequest } = useHttpClient();

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      window.location.href = `${Constants.FRONT_BASE_URL}`;
    }
  }, []);

  const [expand, setExpand] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [formerrors, setFormErrors] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
    
  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const validate = () => {
    let errors = {};
    //password field
    if (!user.password) {
      errors.password = "Password is required";
    }

    //email field
    if (!user.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(user.email)) {
      errors.email = "Email address is invalid";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmit = (event) => {
    if (event) event.preventDefault();
    // console.log("########### onSubmit ###############", EndPoint.LOGIN_API_URL, validate(user));
    if (!validate(user)) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
      setIsLoading(true);
      axios
        .post(EndPoint.LOGIN_API_URL, user)
        .then(
          (response) => {
            setResponce({
              show: true,
              message: "Login Successfully",
              type: "success",
            });console.log('login file fetched');
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.data));

            localStorage.removeItem('mfirstpopup');

            // // save token for push notifiacation
            // let body ={
            //   deviceId: JSON.parse(localStorage.getItem('user')).userId,
            //   deviceToken: localStorage.getItem('fpnt'),
            //   deviceType: 'WEB',
            // };
            // const mResponseData = sendRequest(EndPoint.SAVE_DEVICE_TOKEN, "POST", body);
            // console.log(mResponseData);

            dispatch(authActions.login(response));

            setIsLoading(false);
            setUser({ email: "", password: "" });

            if(localStorage.getItem('currentURL') != '' && localStorage.getItem('currentURL') != null) { 
              const redirectToUrl = localStorage.getItem('currentURL');

              if(redirectToUrl.includes('user/verify-email?token')) {
                localStorage.removeItem('currentURL');
                window.location.href= `${Constants.FRONT_BASE_URL}/user/login`;
              } else if(redirectToUrl.includes('user/login')) {
                localStorage.removeItem('currentURL');
                window.location.href= `${Constants.FRONT_BASE_URL}`;
              } else {
                localStorage.removeItem('currentURL');
                window.location.href= redirectToUrl;
              }
              
            } else {
              window.location.href= `${Constants.FRONT_BASE_URL}`;
            }

            // navigate(`/${Constants.FRONT_BASE_PATH}`);
            // window.location.href= `${Constants.FRONT_BASE_URL}`; comment by M
            // window.location.href = `${EndPoint.FRONT_LOGIN_URL}`;
          },
          (error) => {
            setIsLoading(false);
            setResponce({
              show: true,
              message: error.response.data.message,
              type: "error",
            });
          }
        )
        .catch((e) => {setIsLoading(false); console.log(e)});
    }
  };
  return (
    <>
      <section className="login-section-height">
        <div className="container">
          <div>
            <ToastContainer />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="head-logo head-logo-for-user">
                <ImageTag src={HeadLogo} /> 
                
              </div>
              {/* <div className="heade-title">Welcome to Boost Mobile</div> */}
              <div className="white-outer-box new-login-signup">
                <div className="signin-title">SIGN IN</div>
                {responseObj.show ? (
                  <div
                    className={
                      responseObj.type == "success"
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {responseObj.message}
                  </div>
                ) : (
                  ""
                )}
                <div className="input-sign-in-inner">
                  <Form noValidate>
                    <Form.Group
                      className="mb-3 w-100"
                      controlId="formBasicEmail"
                    >
                      <Form.Control
                        type="email"
                        name="email"
                        value={user.email}
                        onChange={onChangeHandler}
                        placeholder="Enter Email"
                        required
                      />
                      {formerrors.email && (
                        <p className="text-warning">{formerrors.email}</p>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 w-100"
                      controlId="formBasicPassword"
                    >
                      <Form.Control
                        type="password"
                        name="password"
                        value={user.password}
                        onChange={onChangeHandler}
                        placeholder="Enter Password"
                        required
                      />
                      {formerrors.password && (
                        <p className="text-warning">{formerrors.password}</p>
                      )}
                    </Form.Group>
                    <span className="reset-text d-flex justify-content-end">
                      <Link to={`${Constants.FRONT_BASE_PATH}/user/forgot-password`}>Forgot Password</Link>
                    </span>
                    <Button
                      className="btn-primary w-100 mb-3"
                      variant="primary"
                      onClick={onSubmit}
                      type="button"
                      // disabled={!user.email || !user.password}
                    >
                      Login
                    </Button>
                    <div className="text-center dont-account">
                      Don't have an account?
                    </div>
                    <div className="reset-text auto-sign-up w-100">
                      <Link to={`${Constants.FRONT_BASE_PATH}/user/signup`}>Sign Up</Link>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="deactive-cover">
                <div className="deactive-title">Deactivate Your Account</div>
                <p>Don’t need to login or receive notifications anymore? Please deactivate your account by clicking<Link to={`${Constants.FRONT_BASE_PATH}/user/de-activate-user`}> here.</Link></p>
              </div>
            </div>
          </div>
        </div>
        {isLoading?(
          <Loader />
        ):null}
      </section>

    </>
  );
};

export default UserLogin;
