import HomePage from "./pages/HomePage";
import './App.css';
import './assets/css/front.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Constants from "./api/Constants";
import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// User section
import UserLogin from "./components/UserLogin/userLogin";
import UserResetPassword from "./components/UserLogin/userResetPassword";
import UserForgotPassword from "./components/UserLogin/userForgotPassword";
import UserVerifyemail from "./components/UserLogin/userVerifyemail";
import UserSignup from "./components/UserLogin/userSignup";
import UserChangePassword from "./components/UserLogin/userChangePassword";
import DeActivateUser from "./components/UserLogin/de-activate-user";
import UserPDFpage from "./components/UserCategoryList/categoryPdf";
import UserSearchLinkList from "./components/userSearch/user-search-link";
import UserPriceTag from "./components/UserPriceTag";
import UserCategoryList from "./components/UserCategoryList";
import UserAuthenticate from "./components/UserAuthenticate";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { PrivateRoutePriceTags } from "./components/PrivateRoute/PrivateRoutePriceTags";

import { useSelector, useDispatch } from "react-redux";
import AlertsModule from "./components/Alerts/alerts-index";
import { onMessageListener, requestForToken } from "./firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { settingActions } from "./store/setting";

import DigitalFlyersModule from "./components/DigitalFlyers/df-main-index";
import DigitalFlyerFilesModule from "./components/DigitalFlyers/df-files-index";

import listPdfIcon from "./assets/img/alert-list-pdf.svg";
import listImageIcon from "./assets/img/alert-list-image.svg";

const CustomToast = ({ message, imageKey, imageType }) => (
    <a className="mct" href={`${Constants.FRONT_BASE_PATH}/user/alerts`}>
        <div className="n-outer-div">
            <div className="fct"><b>New Notification Received.</b></div>
            <div className="n-message-div fcb">{message}</div>
            {imageType && imageKey && ( // Check if imageType and imageKey are both present
                <div className="n-image-div">
                    {imageType === "application/pdf" ? ( // Display PDF icon if imageType is PDF
                        <img src={listPdfIcon} alt="PDF Icon" />
                    ) : imageType.startsWith("image/") ? ( // Display image if imageType is an image
                        <img className="pushImageIcon" src={imageKey} alt="Notification Image" />
                    ) : ( // Default case, show a placeholder or handle other types
                        <span>Image not available</span>
                    )}
                </div>
            )}
        </div>
    </a>
);

function App() {
    const isAuth = useSelector((state) => state.auth.isAuthenticated);
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(
        () => localStorage.getItem('token') !== null
    );
    useEffect(() => {
        setIsLoggedIn(localStorage.getItem('token'));
    }, [localStorage.getItem('token'), isAuth]);

    useEffect(() => {
        const firebaseConfig = {
            apiKey: Constants.GOOGLE_ANALYTICS_API_KEY,
            authDomain: Constants.GOOGLE_ANALYTICS_AUTH_DOMAIN,
            projectId: Constants.GOOGLE_ANALYTICS_PROJECT_ID,
            storageBucket: Constants.GOOGLE_ANALYTICS_STORAGE_BUCKET,
            messagingSenderId: Constants.GOOGLE_ANALYTICS_MESSAGING_SENDER_ID,
            appId: Constants.GOOGLE_ANALYTICS_APP_ID,
            measurementId: Constants.GOOGLE_ANALYTICS_MEASUREMENT_ID
        };

        // Initialize Firebase
        const app = initializeApp(firebaseConfig);
        // Initialize Analytics and get a reference to the service
        const analytics = getAnalytics(app);

        requestForToken()

    }, []);

    onMessageListener()
        .then((payload) => {
            if (payload && payload.data.message) {
                // toast.success(payload.data.message, {
                toast.success(<CustomToast message={payload.data.message} imageKey={payload.data.filePreviewUrl} imageType={payload.data.attachmentType} />, {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // settingActions.setUnreadNotificationFlag(true);
                dispatch(settingActions.setUnreadNotificationFlag(true));
            } else {
                console.log('Payload or message not found.');
            }
        })
        .catch((err) => console.log('Failed: ', err));

    return (
        <BrowserRouter >
            <ToastContainer />
            <Routes>

                <Route path={`${Constants.FRONT_BASE_PATH}`} element={<PrivateRoute Component={HomePage} />} />

                {/* <Route path={`${Constants.FRONT_BASE_PATH}`} element={isLoggedIn?<HomePage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />       */}
                <Route path={`${Constants.FRONT_BASE_PATH}/login`} element={isLoggedIn ? <HomePage /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />

                <Route path={`${Constants.FRONT_BASE_PATH}/user/login`} element={!isLoggedIn ? <UserLogin /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/`} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/reset-password/:token`} element={<UserResetPassword />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/authenticate/:token`} element={<UserAuthenticate />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/forgot-password`} element={<UserForgotPassword />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/verify-email`} element={<UserVerifyemail />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/signup`} element={<UserSignup />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/change-password`} element={isLoggedIn ? <UserChangePassword /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/de-activate-user`} element={!isLoggedIn ? <DeActivateUser /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/category-pdf`} element={isLoggedIn ? <UserPDFpage /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/user-search-link`} element={isLoggedIn ? <UserSearchLinkList /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />

                <Route path={`${Constants.FRONT_BASE_PATH}/user/price-tag`} element={<PrivateRoutePriceTags Component={UserPriceTag} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/digital-flyers`} element={<PrivateRoutePriceTags Component={DigitalFlyersModule} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/digital-flyers/:flyerId`} element={isLoggedIn ? <DigitalFlyerFilesModule /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />

                <Route path={`${Constants.FRONT_BASE_PATH}/user/categories`} element={isLoggedIn ? <UserCategoryList /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />
                <Route path={`${Constants.FRONT_BASE_PATH}/user/alerts`} element={isLoggedIn ? <AlertsModule /> : <Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />

                <Route
                    path="*"
                    element={<Navigate to={`${Constants.FRONT_BASE_PATH}/`} replace />}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;