import React from "react";
import { Link } from 'react-router-dom';
import ImageTag from "../../components/ImageTag";
import PriceOneIcon from "../../assets/img/price-one.png"
import PricetwoIcon from "../../assets/img/price-two.svg"
import BoostGearIcon from "../../assets/img/boost_gear_ramp.png";
import BoostGearRightIcon from "../../assets/img/drop_color.svg";
import TshirtBoostIcon from "../../assets/img/BoostTshirt.png";
import DigitalFlyerIcon from "../../assets/img/boost-flyers.svg"

import Constants from "../../api/Constants";
import CheckPermissionToPerformAction from "../../components/PrivateRoute/PrivateRouteHook"

import { useState, useEffect } from 'react';

import SignInAsAdmin from "../../components/SignInAsAdmin";

const UserMainIndex = () => {
    const { modulePriceCards, moduleDigitalFlyers } = CheckPermissionToPerformAction();
    
    // console.log('modulePriceCards', modulePriceCards);
    // console.log('moduleDigitalFlyers', moduleDigitalFlyers);
    
    const handleMouseDown = (e) =>{
        const url = "http://boostmobilegear.com/";
        window.open(url, '_blank').focus();
    }

    // check user is admin or not for below link condition
    const user = JSON.parse(localStorage.getItem('user'));

    const [token, setToken] = useState('');

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
        setToken(storedToken);
        }
    }, []);

    return (
        <>
            <section className="price-of-new-inner new-panel-work position-relative">
                <div className="price-main-box">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="price-main-outer d-flex flex-wrap justify-content-center">
                                <div className="price-boxs">
                                    <Link className="price-main-outer-link-box" to={`${Constants.FRONT_BASE_PATH}/user/categories`}>
                                        <div className="price-select-one">
                                            <div>
                                                <ImageTag src={PriceOneIcon} />
                                            </div>
                                            <div className="price-title">Merch Grids</div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="price-boxs">
                                    <div onClick={handleMouseDown} className="price-select-two">
                                        <div className="price-select-two-img">
                                            <ImageTag src={TshirtBoostIcon} />
                                        </div>
                                        <div className="boost-gear-img">
                                            <ImageTag src={BoostGearIcon} />
                                        </div>
                                        <div className="boost-gear-right-icon"><ImageTag src={BoostGearRightIcon} /></div>
                                    </div>
                                </div>
                                

                                {/* M Price Tag Work */}
                                {modulePriceCards && 
                                <div className="price-boxs">
                                <Link className="price-main-outer-link-box" to={`${Constants.FRONT_BASE_PATH}/user/price-tag`}>
                                    <div className="price-select-three">
                                        <div className="price-icon-cover">
                                            {/*<ImageTag src={PricetwoIcon} />*/}
                                            <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
                                                 viewBox='0 0 136.1 104.4'>
                                            <path className="st0" d="M25.8,0.3c5.4,0.3,10.5,1.9,15.7,3c26.4,5.5,52.7,11.3,79.1,16.8c7.3,1.5,12.6,5.2,14.8,12.4
                                                c2.1,7.2,0.1,13.4-5.3,18.8c-9.9,9.9-19.7,20.1-29.5,30.2c-2.1,2.2-4.6,3.7-7.9,4.4C98.8,92,104.9,98,110.9,104
                                                c-0.1,0.2-0.2,0.3-0.3,0.5c-9.8-2.1-19.6-4.1-29.4-6.2C59,93.5,36.9,88.7,14.8,84C7.9,82.6,3.1,78.8,1,72c-2.2-6.8-0.6-12.9,4.3-18
                                                c10.3-10.6,20.7-21.2,31-31.8c2-2,4.4-3.1,7.4-3.9C38.2,12.9,33,7.7,27.8,2.5C27.1,1.8,26.2,1.3,25.8,0.3L25.8,0.3z M94.1,73.8
                                                c0-3.5-1.3-6.4-3.7-8.9C76.3,50.8,62.1,36.7,48,22.6c-1.4-1.4-2.1-1.1-3.2,0.2c-4.1,4.7-3.7,11.7,1,16.4c9.7,9.7,19.4,19.3,29.1,29
                                                c4.5,4.5,9,8.9,13.5,13.5c1.2,1.3,2,1.2,3-0.1C93.1,79.3,94.2,76.7,94.1,73.8z"/>
                                            <path className="st0" d="M25.9,0.3c-0.1,0-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.2C25.7,0.1,25.8,0.2,25.9,0.3C25.8,0.3,25.9,0.3,25.9,0.3z"/>
                                            </svg>
                                        </div>
                                        <div className="price-title">Price Tags</div>
                                    </div>
                                </Link>
                                </div> }
                                
                                {/* M Price Tag Work */}
                                
                                { moduleDigitalFlyers && 
                                <div className="price-boxs">    
                                    <Link className="price-main-outer-link-box digital-box-link" to={`${Constants.FRONT_BASE_PATH}/user/digital-flyers`}>
                                        <div className="price-select-one digital-box">
                                            <div>
                                                <ImageTag src={DigitalFlyerIcon}  className="digital-icon-img"/>
                                            </div>
                                            <div className="price-title">Digital Flyers</div>
                                        </div>
                                    </Link>
                                </div> }

                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            {/* <div className="d-flex justify-content-end mt-5 sign-in-link"> 
                <a className="me-10" href="javascript:void(0)">Need Assistance?</a>
                {user && user.role === 'ADMIN' && (
                    <a href="javascript:void(0)"  onClick={handleGoToAdminLink}>Administrator</a>
                    // {`${Constants.REACT_APP_FRONT_APPLICATION_BASE_URL}`}
                )}
            </div>  */}
            <SignInAsAdmin></SignInAsAdmin>
        </>

    );
};
export default UserMainIndex;