import { useEffect, useState } from "react";
import Navbar from "../../components/NavBar/userNav";
import Loader from "../../components/Loading/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignInAsAdmin from "../SignInAsAdmin";
import { Button, Spinner } from "react-bootstrap";
import { useHttpClient } from "../../hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
import LeftArrow from "./../../assets/img/angle-left-solid.svg";
import { Link, useParams } from "react-router-dom";

const DigitalFlyersFileIndex = () => {

    const [modulesList, setModulesList] = useState([]);
    const { sendRequest } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [inputVal, setInputVal] = useState("");
    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const { flyerId } = useParams();
    const [initLoadMore, setInitLoadMore] = useState(true);
    const [fileData, setFileData] = useState(null);
    const [checkedFiles, setCheckedFiles] = useState([]);
    const [resultCountText, setResultCountText] = useState("");

    const fetchFilesData = async (pageArg, initialLoad) => {
        const pageSize = Constants.FLYER_PAGINATION;

        const body = {
            page: pageArg,
            pageSize: pageSize,
            folderKey: flyerId,
            searchText: inputVal.trim()
        };

        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.DIGITAL_FLYER_FILES_LIST_WITH_FILTER,
                "POST",
                body
            );

            setIsLoading(false);
            // Reset checked files when searching for new files
            setCheckedFiles([]);

            // Calculate the number of records loaded so far
            const loadedDataLength = initialLoad ? pageSize : (pageArg * pageSize);
            const totalCountVar = responseData.totalNumOfFiles;

            // Ensure the length does not exceed the total count
            const displayedRecords = Math.min(loadedDataLength, totalCountVar);
            
            let mCategoryTextOnTop;
            if(displayedRecords > 0) {
                mCategoryTextOnTop = `Searched by: ${inputVal} - (Showing ${displayedRecords} records out of ${totalCountVar} results)`;
            } else {
                mCategoryTextOnTop = 2;
            }
            setResultCountText(mCategoryTextOnTop);

            // Determine if the load more button should be shown
            setShowLoadMoreButton(displayedRecords < totalCountVar);

            const initialDataArr = responseData.allFlyersCategoryFiles;
            if (initialLoad) {
                setModulesList(initialDataArr);
                setInitLoadMore(false);
            } else {
                setModulesList((prevList) => [...prevList, ...initialDataArr]);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchFilesDataInitialLoad = async () => {
        const body = { folderKey: flyerId };

        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.DIGITAL_FLYER_FILES_INITIAL_LOAD_DATA,
                "POST",
                body
            );
            
            // Reset checked files when searching for new files
            setCheckedFiles([]);

            if (responseData) {
                setSelectedCategoryName(responseData.categoryName);
                setFileData(responseData);
            }

            setInitLoadMore(false);
            setIsLoading(false);
            setShowLoadMoreButton(false);
        } catch (err) {
            setInitLoadMore(false);
            setIsLoading(false);
            setShowLoadMoreButton(false);
        }
    };

    const handleLoadMore = () => {
        const pageCount = pageNumber;
        setPageNumber(pageCount + 1);
        fetchFilesData(pageCount + 1);
    };

    const handleFileDownload = async () => {
        if (checkedFiles.length === 0) {
            toast.error('Please select at least one file to download.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }

        const body = {
            fileKeys: checkedFiles,
            folderKey: flyerId
        };

        setIsLoading(true);
        try {
            const response = await sendRequest(
                EndPoint.DOWNLOAD_DIGITAL_FLYER_FILES,
                "POST",
                body
            );

            const downloadFilesSequentially = async (signedUrls) => {
                for (let i = 0; i < signedUrls.length; i++) {
                    const signedUrlObj = signedUrls[i];
                    const downloadLink = document.createElement('a');
                    downloadLink.href = signedUrlObj.downloadUrl;
    
                    const fileName = signedUrlObj.downloadUrl.substring(signedUrlObj.downloadUrl.lastIndexOf('/') + 1);
                    downloadLink.setAttribute('download', fileName);
    
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
    
                    await new Promise(resolve => setTimeout(resolve, 3000));  // 500ms delay
                }
            };
    
            await downloadFilesSequentially(response.signedUrls);

            setIsLoading(false);
        } catch (error) {
            toast.error('Error downloading files, please try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            console.error("Error downloading files:", error);
            setIsLoading(false);
        }
    };

    const onChangeHandler = (event) => {
        setInputVal(event.target.value);
    };

    const onKeyDownHandler = (event) => {
        if (event.key === "Enter") {
            if (inputVal.trim().length < 3) {
                toast.error('Please enter at least 3 characters.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            fetchFilesData(1, true);
        }
    };

    const handleCheckboxChange = (fileName) => {
        setCheckedFiles((prevCheckedFiles) => {
            const isChecked = prevCheckedFiles.includes(fileName);
            let newCheckedFiles;
    
            if (isChecked) {
                newCheckedFiles = prevCheckedFiles.filter((file) => file !== fileName);
            } else {
                if (prevCheckedFiles.length >= 10) {
                    // Show toast message outside of the state update to avoid double rendering
                    setTimeout(() => {
                        toast.error('You can only select and download up to 10 files at a time.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }, 0);
                    return prevCheckedFiles;
                }
                newCheckedFiles = [...prevCheckedFiles, fileName];
            }
    
            return newCheckedFiles;
        });
    };
    
    useEffect(() => {
        fetchFilesDataInitialLoad();
    }, [flyerId]);

    return (
        <>
            <Navbar />
            <ToastContainer />
            <div className="main-cover">
            <div className="container">
                    <div className="row mt-4 mb-6 d-flex justify-content-between align-items-center">
                        <span className="col-lg-3 col-md-3 col-12 text-left mb-2">
                            <Link className="Back-btn" variant="primary" to={`${Constants.FRONT_BASE_PATH}/user/digital-flyers`}>
                                <span><img src={LeftArrow} /></span>Back
                            </Link>
                        </span>
                        <span className="col-lg-9 col-md-9 col-12 text-left text-md-right mb-3 files-listing-title">
                            {selectedCategoryName}
                        </span>


                    </div>
                    <div className="row">
                        <span className="col-lg-12 col-md-12 col-12 text-left text-md-right mb-2">
                            <div className="search-inner">
                                <div className="wrapper">
                                    <input onKeyDown={onKeyDownHandler} onChange={onChangeHandler} value={inputVal} placeholder="Search by flyer name, store name, your name, etc" className="input-search w-100 flyer-search" />
                                </div>
                            </div>
                        </span>
                    </div>
                    <div className="row mt-3">
                        <div className="col-xl-4 col-lg-6 col-md-12 col-12 file-list-left-border">
                            <div className="card file-list-left-box">
                            {fileData && fileData.signedUrl ? (
                                <>
                                    {fileData.fileType.toLowerCase() === 'pdf' && fileData.fileName !== '' ? (
                                        <iframe
                                            src={fileData.signedUrl}
                                            width="100%"
                                            height="500"
                                            title="PDF Viewer"
                                        />
                                    ) : (
                                        <img
                                            src={fileData.signedUrl}
                                            alt="File Preview"
                                            style={{ maxWidth: '100%' }}
                                        />
                                    )}
                                </>
                            ) : (
                                <div>{isLoading ? "Loading..." : "No file to display"}</div>
                            )}
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-6 col-md-12 col-12 file-list-right">
                            <div className="text-right mb-3">

                            </div>

                            {modulesList.length > 0 ? (
                                <div className="table-responsive">
                                    <table id="tezst" className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th colSpan="2" className="text-left">{resultCountText}</th>
                                                <th className="text-right w-200">
                                                    {resultCountText && (
                                                        <Button onClick={handleFileDownload} className="action-icons">
                                                            DOWNLOAD
                                                        </Button>
                                                    )}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modulesList.map((element, index) => (
                                                <tr key={index}>
                                                    <td width="40">
                                                        <input
                                                            type="checkbox"
                                                            id={`mid`+index}
                                                            checked={checkedFiles.includes(element.fileName)}
                                                            onChange={() => handleCheckboxChange(element.fileName)}
                                                        />
                                                    </td>
                                                    <td colSpan="2" title={element.fileName}>
                                                        <label htmlFor={`mid`+index} className="mb-0">{element.fileName}</label>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="no-records-message align-content-center" align="center">
                                    {isLoading
                                        ? "Loading records..."
                                        : (!resultCountText && resultCountText !== 2)
                                        ? "Start by typing the name in the search field..."
                                        : "No flyer found with search criteria."}
                                </div>
                            )}


                            {
                                showLoadMoreButton && !initLoadMore &&
                                <div className="text-center load-btn">
                                    <button onClick={handleLoadMore} type="button" className="btn ">
                                        Load More...
                                    </button>
                                </div>
                            }

                            {isLoading ? (<Loader />) : null}

                            {initLoadMore && <div className="text-center">
                                <Spinner animation="border" />;
                            </div>}
                        </div>
                    </div>


                </div>

                <SignInAsAdmin></SignInAsAdmin></div>

        </>
    );
};

export default DigitalFlyersFileIndex;
